export const mungeParameters = (parameters: {
  [key: string]: string[];
}): URLSearchParams => {
  const params = new URLSearchParams();
  Object.entries(parameters).map(([key, value]: any) => {
    if (value === undefined) {
      return;
    }
    value.map((v: string) => {
      params.append(key, v);
    });
  });
  return params;
};
export const sampleEmail = {
  subject: "Updates from March",
  body: `## Newer, nicer settings pages

It’s boring to talk about changes to settings pages, I know — they’re sort of the unfinished basement of any application, useful in its own
way but best left unshown to house guests. But running a newsletter has a lot of fiddly bits, and we want to make it as pleasant as possible
for you to accomplish whatever you’re trying to accomplish.

ow, when you click on Settings, you’re taken to a smaller page with the bare essentials, plus a bunch of other links to dive into specifics
like Integrations, Notifications, and so on — we hope this makes it easier for new authors to get started and existing authors to find what
they’re looking for more easily.

You can read more about the new settings pages (https://buttondown.com/changelog/2024-03-14), or log in to play around with them!

## Fancy new editor

![](https://buttondown.com/next-assets/img/fancy-mode.png)

It’s still in beta, but we’re letting folks give the (literally titled) Fancy Editor Mode out for a spin, which tweaks the existing editor to
add an in-line ribbon and a Notion-style command menu:

![](https://buttondown.com/next-assets/img/fancy-mode-2.png)

![](https://buttondown.com/next-assets/img/fancy-mode-3.png)

Not only is this a nice replacement for the existing experience, what both of these things due is allow for room for growth. Things that we’ve
always wanted to build into the editor — support for custom fonts and colors or more smart embeds — suddenly have a place and a path as
opposed to being stuffed into Yet Another Dropdown Menu.

## From the blog

We’ve been blogging a lot:

- We dig into the data (and the vibes) to answer one of the most common questions a new author asks: ["how often should I send my
newsletter?”](https://buttondown.com/blog/how-often-should-you-send-your-newsletter)
- We chatted with two lovely Buttondown authors, [Rian van der Merwe](https://buttondown.com/stories/rian-van-der-merwe) and [Sam
Rose](https://buttondown.com/stories/sam-rose), about how they carved out a space for themselves online
- Ever wonder what software Buttondown is using under the hood to keep things humming along? We published
(https://buttondown.com/blog/updates-to-the-stack) to cover changes from the past few months.

## Smaller stuff

We’ve also added:

1. An [RSS feed for your comments](https://buttondown.com/changelog/2024-03-18)
2. A [daily report](https://buttondown.com/changelog/2024-03-15) if you’re too antsy to wait for a weekly one
3. [Sharing integrations with Bluesky and Mastodon](https://buttondown.com/changelog/2024-03-12)`,
};

import { Fetcher } from "openapi-typescript-fetch";

import { paths } from "@/autogen/internal-openapi";
import { useStore } from "@/store/stores/scaffolding";

const api = Fetcher.for<paths>();

const intercept = (url: string, init: RequestInit, next: any) => {
  const store = useStore();
  if (store.newsletter === undefined || store.newsletter === null) {
    return next(url, init);
  }
  const token = store.newsletter.api_key;
  return next(url, {
    ...init,
    headers: new Headers({
      ...init.headers,
      Authorization: `Token ${token}`,
    }),
  });
};

api.configure({
  baseUrl: "/api/emails/api",
  use: [intercept],
});

export default api;

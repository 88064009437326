import { defineStore } from "pinia";
import { ref } from "vue";

import { components as OpenAPI } from "@/autogen/openapi";

export const useStore = defineStore("errors", () => {
  const resource = ref<OpenAPI["schemas"]["ErrorMessage"][]>([]);
  const add = (error: OpenAPI["schemas"]["ErrorMessage"]) => {
    resource.value.push(error);
  };

  return {
    resource,
    add,
  };
});

import axios from "axios";
import { TypedFetch } from "openapi-typescript-fetch";
import { Ref, ref, watch } from "vue";

import { Parameter } from "@/components/Layout/ParameterWidget/lib";
import { mungeParameters } from "@/lib/utils";
import whenNewsletterChanges from "@/lib/whenNewsletterChanges";
import { safeLocalStorageGetItem } from "@/store/local_storage";
import { useStore } from "@/store/stores/scaffolding";
import { Aggregate } from "@/types/aggregate";

import { useStore as useErrorsStore } from "./errors";

export type ListResource<
  Model,
  Ordering,
  Parameters extends { ordering?: Ordering }
> = {
  resource: Ref<Model[]>;
  error: Ref<string | undefined>;
  pending: Ref<boolean>;
  list: () => Promise<void>;
  count: Ref<number>;
  // It's weird and janky that we have to omit `ids` as well, which is only present in the internal events
  // endpoint. At some point, we need to figure out how to dynamically declare a mapping of "real parameters"
  // that we want to expose in the UI, because otherwise we have to manually denylist filters that appear in OpenAPI
  // but are not actually used in the UI.
  parameters: Ref<Omit<Parameters, "page" | "ids">>;
  setParameters: (parameters: Parameters) => void;
  pages: Ref<number[]>;
  ordering: Ref<Parameters["ordering"] | null>;
};

export type List<Model, Parameters> = TypedFetch<{
  parameters?: {
    query: Parameters;
  };
  responses: {
    200: {
      content: {
        "application/json": {
          results: Model[];
          count: number;
        };
      };
    };
  };
}>;

type Create<ModelInput, Model> = TypedFetch<{
  requestBody: {
    content: {
      "application/json": ModelInput;
    };
  };
  responses: {
    201: {
      content: {
        "application/json": Model;
      };
    };
  };
}>;

type Update<ModelUpdateInput, Model> = TypedFetch<{
  requestBody: {
    content: {
      "application/json": ModelUpdateInput;
    };
  };
  responses: {
    200: {
      content: {
        "application/json": Model;
      };
    };
  };
}>;

type Delete<ModelDeletionInput> = TypedFetch<{
  parameters: {
    path: ModelDeletionInput;
  };
  responses: {
    204: never;
    [code: number]: any;
  };
}>;

type CreateResource<Model, ModelInput> = {
  create: (model: ModelInput) => Promise<Model>;
  creating: Ref<boolean>;
};

type CreateListResource<
  Model,
  ModelInput,
  Ordering,
  Parameters extends { ordering?: Ordering }
> = ListResource<Model, Ordering, Parameters> &
  CreateResource<Model, ModelInput>;

type UpdateResource<Model, ModelUpdateInput> = {
  update: (
    model: ModelUpdateInput,
    optimistic?: (model: Model) => boolean
  ) => Promise<Model>;
  updating: Ref<boolean>;
  updateAbortController: Ref<AbortController | null>;
};

type CreateListUpdateResource<
  Model,
  ModelInput,
  ModelUpdateInput,
  Ordering,
  Parameters extends { ordering?: Ordering }
> = CreateListResource<Model, ModelInput, Ordering, Parameters> &
  UpdateResource<Model, ModelUpdateInput>;

type DeleteResource<ModelDeletionInput> = {
  delete: (model: ModelDeletionInput) => Promise<void>;
  deleting: Ref<boolean>;
};

type CreateListUpdateDeleteResource<
  Model,
  ModelInput,
  ModelUpdateInput,
  ModelDeletionInput,
  Ordering,
  Parameters extends { ordering?: Ordering }
> = CreateListUpdateResource<
  Model,
  ModelInput,
  ModelUpdateInput,
  Ordering,
  Parameters
> &
  DeleteResource<ModelDeletionInput>;

export const useListResource = <
  Model,
  Ordering,
  Parameters extends { ordering?: Ordering; expand?: string[] }
>(
  fetch: List<Model, Parameters>,
  defaultParameters: Parameters = {} as Parameters,
  additionalParameters: Parameters = {} as Parameters
): ListResource<Model, Ordering, Parameters> => {
  const resource = ref<Model[]>([]) as Ref<Model[]>;
  const count = ref<number>(0);

  const pending = ref<boolean>(false);
  const error = ref<string | undefined>(undefined);

  const parameters = ref(defaultParameters) as Ref<Parameters>;
  const pages = ref<number[]>([1]);
  const ordering = ref<Ordering | null>(null) as Ref<Ordering | null>;

  let controller: AbortController | undefined;

  const list = async () => {
    pending.value = true;
    controller = new AbortController();

    try {
      const response = await fetch(
        {
          ...parameters.value,
          ...additionalParameters,
          ordering: ordering.value,
          page: pages.value[pages.value.length - 1],
        },
        {
          signal: controller.signal,
        }
      );

      if (pages.value.length > 1) {
        resource.value = [...resource.value, ...response.data.results];
      } else {
        resource.value = response.data.results;
      }

      count.value = response.data.count;

      pending.value = false;
      error.value = undefined;
    } catch (err) {
      if (err instanceof Error && err.name === "AbortError") {
        return;
      }

      pending.value = false;
      error.value = `An unknown error occured.`;
    }
  };

  const setParameters = (params: Parameters) => {
    parameters.value = params;
  };

  const scaffoldingStore = useStore();

  watch(
    pages,
    (pages) => {
      if (pages.length > 1) {
        list();
      }
    },
    { deep: true }
  );

  watch(
    [() => scaffoldingStore.newsletter?.id, ordering, parameters],
    () => {
      if (pages.value.length > 1) {
        pages.value = [1];
      } else {
        list();
      }
    },
    { deep: true }
  );

  return {
    resource,
    error,
    pending,
    count,
    list,
    parameters,
    setParameters,
    pages,
    ordering,
  };
};

export const useCreateResource = <Model, ModelInput>(
  resource: Ref<Model[]>,
  create: Create<ModelInput, Model>
): CreateResource<Model, ModelInput> => {
  const creating = ref(false);

  const createResource = async (model: ModelInput): Promise<Model> => {
    creating.value = true;
    try {
      const response = await create(model);
      resource.value = [response.data, ...resource.value];
      return response.data;
    } finally {
      creating.value = false;
    }
  };

  return {
    create: createResource,
    creating,
  };
};

export const useCreateListResource = <
  Model,
  ModelInput,
  Ordering,
  Parameters extends { ordering?: Ordering }
>(
  list: List<Model, Parameters>,
  create: Create<ModelInput, Model>,
  defaultParameters: Parameters = {} as Parameters,
  additionalParameters: Parameters = {} as Parameters
): CreateListResource<Model, ModelInput, Ordering, Parameters> => {
  const listResource = useListResource(
    list,
    defaultParameters,
    additionalParameters
  );
  return {
    ...listResource,
    ...useCreateResource(listResource.resource, create),
  };
};

export const useUpdateResource = <
  Model extends { id: string },
  ModelUpdateInput
>(
  resource: Ref<Model[]>,
  update: Update<ModelUpdateInput, Model>
): UpdateResource<Model, ModelUpdateInput> => {
  const errorsStore = useErrorsStore();

  const updating = ref(false);
  const updateAbortController = ref<AbortController | null>(null);

  const updateResource = async (
    model: ModelUpdateInput,
    optimistic?: (model: Model) => boolean
  ): Promise<Model> => {
    updating.value = true;
    updateAbortController.value = new AbortController();
    try {
      if (optimistic) {
        resource.value = resource.value.map((item) => {
          if (optimistic?.(item)) {
            return { ...item, ...model };
          }
          return item;
        });
      }
      const response = await update(model, {
        signal: updateAbortController.value.signal,
      });
      resource.value = resource.value.map((item) => {
        if (item.id === response.data.id) {
          // Some special-casing here: we assume that expansions stay the same.
          // Which is to say, replace `item` with `response.data` but keep any non-null
          // values A of `item` for which there exists a corresponding non-null value A_id
          // in `response.data` which has not changed.
          const potentialExpansionKeys = Object.keys(item).filter((key) =>
            key.endsWith("_id")
          );
          const expansionsToKeep = potentialExpansionKeys.filter((key) => {
            const itemValue = item[key as keyof Model];
            const responseValue = response.data[key as keyof Model];
            return (
              itemValue !== null &&
              responseValue !== null &&
              itemValue === responseValue
            );
          });
          const objectWithKeptExpansions = Object.fromEntries(
            Object.entries(item).filter(([key]) =>
              expansionsToKeep.includes(key + "_id")
            )
          );
          return { ...response.data, ...objectWithKeptExpansions };
        }
        return item;
      });
      updating.value = false;
      return response.data;
    } catch (e: any) {
      if (e.data) {
        errorsStore.add(e.data);
      }
      updating.value = false;
      throw e;
    }
  };

  return {
    update: updateResource,
    updating,
    updateAbortController,
  };
};

export const useListUpdateResource = <
  Model extends { id: string },
  ModelUpdateInput,
  Ordering,
  Parameters extends { ordering?: Ordering; expand?: string[] }
>(
  list: List<Model, Parameters>,
  update: Update<ModelUpdateInput, Model>,
  defaultParameters: Parameters = {} as Parameters,
  additionalParameters: Parameters = {} as Parameters
) => {
  const listResource = useListResource(
    list,
    defaultParameters,
    additionalParameters
  );
  return {
    ...listResource,
    ...useUpdateResource(listResource.resource, update),
  };
};

export const useCreateListUpdateResource = <
  Model extends { id: string },
  ModelInput,
  ModelUpdateInput,
  Ordering,
  Parameters extends { ordering?: Ordering }
>(
  list: List<Model, Parameters>,
  create: Create<ModelInput, Model>,
  update: Update<ModelUpdateInput, Model>,
  defaultParameters: Parameters = {} as Parameters,
  additionalParameters: Parameters = {} as Parameters
): CreateListUpdateResource<
  Model,
  ModelInput,
  ModelUpdateInput,
  Ordering,
  Parameters
> => {
  const resource = useCreateListResource(
    list,
    create,
    defaultParameters,
    additionalParameters
  );
  return {
    ...resource,
    ...useUpdateResource(resource.resource, update),
  };
};

const useDeleteResource = <
  Model extends { id: string },
  ModelDeletionInput extends { id: string }
>(
  resource: Ref<Model[]>,
  del: Delete<ModelDeletionInput>
): DeleteResource<ModelDeletionInput> => {
  const deleting = ref(false);
  const deleteResource = async (model: ModelDeletionInput): Promise<void> => {
    deleting.value = true;
    await del(model);
    resource.value = resource.value.filter((item) => {
      return item.id !== model.id;
    });
    deleting.value = false;
    return;
  };

  return {
    delete: deleteResource,
    deleting,
  };
};

export const useCreateListUpdateDeleteResource = <
  Model extends { id: string },
  ModelInput,
  ModelUpdateInput,
  ModelDeletionInput extends { id: string },
  Ordering,
  Parameters extends { ordering?: Ordering }
>(
  list: List<Model, Parameters>,
  create: Create<ModelInput, Model>,
  update: Update<ModelUpdateInput, Model>,
  del: Delete<ModelDeletionInput>,
  defaultParameters: Parameters = {} as Parameters
): CreateListUpdateDeleteResource<
  Model,
  ModelInput,
  ModelUpdateInput,
  ModelDeletionInput,
  Ordering,
  Parameters
> => {
  const resource = useCreateListUpdateResource(
    list,
    create,
    update,
    defaultParameters
  );
  return {
    ...resource,
    ...useDeleteResource(resource.resource, del),
  };
};

export const useAggregate = <ListParameter extends string>(
  endpoint: string,
  defaultParameters: Parameter<ListParameter> = {} as Parameter<ListParameter>
): {
  fieldToGlobalAggregateCount: Ref<{
    [key in ListParameter]?: Aggregate<string>[];
  }>;
  fieldToAggregateCount: Ref<{ [key in ListParameter]?: Aggregate<string>[] }>;
  aggregate: (parameters: Parameter<ListParameter>) => Promise<void>;
  aggregateGlobal: () => Promise<void>;
} => {
  const abortController = ref<AbortController | null>(null);
  const fieldToAggregateCount: Ref<{
    [key in ListParameter]?: Aggregate<string>[];
  }> = ref({});
  const fieldToGlobalAggregateCount: Ref<{
    [key in ListParameter]?: Aggregate<string>[];
  }> = ref({});

  const aggregate = async (parameters: Parameter<ListParameter>) => {
    if (abortController.value) {
      abortController.value.abort();
    }
    abortController.value = new AbortController();
    const response = await axios.get(endpoint, {
      params: mungeParameters(parameters),
      signal: abortController.value?.signal,
    });
    fieldToAggregateCount.value = response.data;
  };

  const aggregateGlobal = async () => {
    fieldToGlobalAggregateCount.value = (
      await axios.get(endpoint, {
        params: mungeParameters(defaultParameters),
      })
    ).data;
  };

  whenNewsletterChanges(aggregateGlobal);

  return {
    fieldToGlobalAggregateCount,
    fieldToAggregateCount,
    aggregate,
    aggregateGlobal,
  };
};

export const useLocalStorageBackedRef = <T>(
  key: string,
  defaultValue: T,
  serialize: (value: T) => string,
  deserialize: (value: string) => T
): Ref<T> => {
  const value = ref(
    // We use ?? and not || so that we can correctly store `false`.
    deserialize(safeLocalStorageGetItem(key) || "") ?? defaultValue
  ) as Ref<T>;
  watch(
    value,
    (value) => {
      localStorage.setItem(key, serialize(value));
    },
    {
      deep: true,
    }
  );
  return value;
};

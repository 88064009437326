import { Fetcher } from "openapi-typescript-fetch";

import { paths } from "@/autogen/openapi";
import { useStore } from "@/store/stores/scaffolding";

const api = Fetcher.for<paths>();

const intercept = (url: string, init: RequestInit, next: any) => {
  const store = useStore();
  if (store.newsletter === undefined || store.newsletter === null) {
    return next(url, init);
  }

  const token = store.newsletter.api_key;

  // This is janky, but the right way to do it for now.
  if (store.newsletter.stripe_account === "") {
    if (url.includes("/prices") || url.includes("/coupons")) {
      return {
        status: 209,
        url,
        data: { results: [] },
        headers: new Headers(),
      };
    }
  }

  return next(url, {
    ...init,
    headers: new Headers({
      ...init.headers,
      Authorization: `Token ${token}`,
      "X-API-Version": "2024-09-30",
    }),
  });
};

api.configure({
  baseUrl: "/v1",
  use: [intercept],
});

export default api;

import { computed, watch } from "vue";

import { useStore as useScaffoldingStore } from "@/store/stores/scaffolding";

export default function (callable: () => void) {
  callable();

  const scaffoldingStore = useScaffoldingStore();
  const newsletter = computed(() => scaffoldingStore.newsletter);
  watch(
    () => newsletter.value?.id,
    () => {
      callable();
    }
  );
}

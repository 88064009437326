import { ref } from "vue";

import { uuid } from "@/utils";

export type ToastType = "inform" | "warn" | "error" | "pending" | "success";

export interface ToastOptions {
  type: ToastType;
  title: string;
  message?: string;
}

export interface InternalToast {
  id: string;
  type: ToastType;
  title: string;
  message?: string;
}

const toasts = ref<InternalToast[]>([]);

export { toasts as UNSTABLE_toasts };

export const showToast = (options: ToastOptions): string => {
  const toastId = uuid();

  toasts.value.push({ ...options, id: toastId });
  return toastId;
};

export const removeToastById = (id: string): void => {
  toasts.value = toasts.value.filter((toast) => toast.id !== id);
};

const partition = function <T>(
  arr: T[],
  criteria: (t: T) => boolean
): [T[], T[]] {
  return [
    arr.filter(function (item: T): boolean {
      return criteria(item);
    }),
    arr.filter(function (item: T): boolean {
      return !criteria(item);
    }),
  ];
};

// Via https://stackoverflow.com/a/46700791.
const notEmpty = <TValue>(
  value: TValue | null | undefined
): value is TValue => {
  return value !== null && value !== undefined;
};

const unique = <T>(arr: T[]): T[] => {
  return Array.from(new Set(arr));
};

// Via https://stackoverflow.com/a/74823834.
type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];
const entries = <T extends object>(obj: T) => Object.entries(obj) as Entries<T>;

const fromEntries = <T extends object>(objectEntries: Entries<T>) =>
  Object.fromEntries(objectEntries) as T;

export { entries, fromEntries, notEmpty, partition, unique };
